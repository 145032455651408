module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"dubspot website","short_name":"dubspot","start_url":"/","background_color":"#1753B2","theme_color":"#1753B2","display":"minimal-ui","icon":"src/images/dubspot-icon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-prettier-build/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-prismjs","options":{}},{"resolve":"gatsby-remark-images","options":{"maxWidth":900,"linkImagesToOriginal":false}},{"resolve":"gatsby-remark-external-links","options":{"target":"_blank","rel":"nofollow"}}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
