import React from "react"
import { Link } from "gatsby"

const BackButton = ({ className, to, text }) => {
  return (
    <Link
      className={className}
      to={to}
      css={`
        text-decoration: none;
        color: var(--gray-700);
      `}
    >
      <div
        css={`
          display: flex;
          align-content: center;
          margin: 0 0 1rem;
        `}
      >
        <svg
          css={`
            align-self: normal;
          `}
          width="20px"
          viewBox="0 0 20 20"
        >
          <path
            fill="#333"
            d="M13.891 17.418c.268.272.268.709 0 .979s-.701.271-.969 0l-7.83-7.908a.697.697 0 0 1 0-.979l7.83-7.908a.68.68 0 0 1 .969 0 .697.697 0 0 1 0 .979L6.75 10l7.141 7.418z"
          />
        </svg>
        <span
          css={`
            align-self: normal;
          `}
        >
          {text}
        </span>
      </div>
    </Link>
  )
}

export default BackButton
